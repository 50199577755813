<template>
  <Loader />
</template>

<script>
import Loader from "@/components/Loader.vue";
import { jwtDecode } from "jwt-decode";
import { getSsoAccessToken } from "@/api/sso.api";

export default {
  name: "callback",
  components: {
    Loader,
  },
  async mounted() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get('token');

    if (token) {
      var accessToken = (await getSsoAccessToken(token)).data.access_token;

      if (urlParams.get('init')) {
        const decoded = jwtDecode(accessToken);

        localStorage.setItem("merchantId", decoded.app);
        localStorage.setItem("bearer", accessToken);
      }
      else {
        localStorage.setItem("token", accessToken);
      }

      window.location.href = "/";
    }
  },
};
</script>